<template>
  <div>
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        visible
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="Дата от">
                <el-date-picker-input
                  v-model="filter.date_from"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy"
                  @change="updateAuctionStatistics('date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Дата до">
                <el-date-picker-input
                  v-model="filter.date_to"
                  type="datetime"
                  autocomplete="false"
                  format="dd.MM.yyyy"
                  @change="updateAuctionStatistics('date_to')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Статистика ауционов
      </b-card-header>
      <b-card-body>
        <v-client-table
          v-if="auctionStatistic"
          :columns="columns"
          :options="options"
          :theme="theme"
          :data="auctionStatistic"
        >
          <span
            slot="reportDate"
            slot-scope="props"
          >
            {{ formatDate(props.row.reportDate) }}
          </span>
          <span
            slot="acceptedRoutesPercent"
            slot-scope="props"
          >
            {{ props.row.acceptedRoutesNumber/props.row.routesNumber*100 }}%
          </span>
          <span
            slot="bidsNOffersNumber"
            slot-scope="props"
          >
            {{ props.row.bidsNumber+props.row.offersNumber }}
          </span>
          <span
            slot="profit"
            slot-scope="props"
          >
            {{ props.row.endPriceSum-props.row.startingPriceSum }}
          </span>
        </v-client-table>
      </b-card-body>
    </b-card>
    <auctions-in-statistic
      :date-from="filter.date_from"
      :date-to="filter.date_to"
    />
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue'
import Vue from 'vue';
import {Event, ClientTable} from 'vue-tables-2';
import {auctionStatistics} from '@/services/api';
import moment from 'moment';
import AuctionsInStatistic from '@/views/Statistic/AuctionsInStatisitic';

Vue.use(ClientTable);
export default {
  name: 'AuctionStatistic',
  components: {AuctionsInStatistic, ElDatePickerInput},
  data: function() {
    return {
      columns: [
        'reportDate',
        'routeOwner.name',
        'routesNumber',
        'acceptedRoutesNumber',
        'acceptedRoutesPercent',
        'bidsNOffersNumber',
        'bidsNumber',
        'offersNumber',
        'startingPriceSum',
        'endPriceSum',
        'profit',
      ],
      auctionStatistic: [],
      filter: {
        date_from: new Date((new Date()).getTime() - (60*60*24*70*1000)),
        date_to: new Date(),
      },
      options: {
        headings: {
          'reportDate': 'Дата',
          'routeOwner.name': 'Менеджер',
          'startingPriceSum': 'Первоначальная стоимость рейсов в аукционе',
          'endPriceSum': 'Сумма конечных ставок/встречных предложений утвержденных аукционов',
          'routesNumber': 'Кол-во рейсов',
          'acceptedRoutesNumber': 'Кол-во утвержденных рейсов',
          'acceptedRoutesPercent': 'Процент утвержденных рейсов',
          'bidsNOffersNumber': 'Кол-во ставок/встречных предложений',
          'bidsNumber': 'Кол-во ставок',
          'offersNumber': 'Кол-во встречных предложений',
          'profit': 'Экономия/Перерасход',
        },
        sortable: [],
        filterable: [],

        perPage: 10000,
        perPageValues: [],
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  mounted() {
    this.updateAuctionStatistics();
  },
  methods: {
    formatDate: function( date) {
      return moment(date ).format('DD.MM.YYYY');
    },
    updateAuctionStatistics: async function() {
      const response = await auctionStatistics(this.$store.state.user.ownerId, {
        date_from: moment(this.filter.date_from).format('YYYY-MM-DD'),
        date_to: moment(this.filter.date_to).format('YYYY-MM-DD'),
      });
      const responseStatistic = response.data;
      const sumStatistics = {};
      responseStatistic.forEach((auctionStatistic)=>{
        if (!sumStatistics[auctionStatistic.reportDate]) {
          sumStatistics[auctionStatistic.reportDate] = {
            reportDate: auctionStatistic.reportDate,
            routesNumber: 0,
            acceptedRoutesNumber: 0,
            bidsNumber: 0,
            offersNumber: 0,
            startingPriceSum: 0,
            endPriceSum: 0,
            routeOwner: {name: 'Все за день'},
            children: [],
          };
        }
        sumStatistics[auctionStatistic.reportDate].routesNumber += auctionStatistic.routesNumber;
        sumStatistics[auctionStatistic.reportDate].acceptedRoutesNumber += auctionStatistic.acceptedRoutesNumber;
        sumStatistics[auctionStatistic.reportDate].bidsNumber += auctionStatistic.bidsNumber;
        sumStatistics[auctionStatistic.reportDate].offersNumber += auctionStatistic.offersNumber;
        sumStatistics[auctionStatistic.reportDate].startingPriceSum += auctionStatistic.startingPriceSum;
        sumStatistics[auctionStatistic.reportDate].endPriceSum += auctionStatistic.endPriceSum;
        sumStatistics[auctionStatistic.reportDate].children.push(auctionStatistic);
      });
      const outputTable = [];
      const total = {
        routesNumber: 0,
        acceptedRoutesNumber: 0,
        bidsNumber: 0,
        offersNumber: 0,
        startingPriceSum: 0,
        endPriceSum: 0,
        routeOwner: {name: 'Все за период'},
      };
      for (const date in sumStatistics) {
        total.routesNumber += sumStatistics[date].routesNumber;
        total.acceptedRoutesNumber += sumStatistics[date].acceptedRoutesNumber;
        total.bidsNumber += sumStatistics[date].bidsNumber;
        total.offersNumber += sumStatistics[date].offersNumber;
        total.startingPriceSum += sumStatistics[date].startingPriceSum;
        total.endPriceSum += sumStatistics[date].endPriceSum;
        outputTable.push(sumStatistics[date]);
        sumStatistics[date].children.forEach((child)=>outputTable.push(child));
      }
      outputTable.push(total);
      this.auctionStatistic = outputTable;
    },
  },
};
</script>

<style scoped>

</style>
